export const kutuphaneSpecs = [
  {
    text: "14 gün boyunca ücretsiz!",
    href: "https://www.kodvizit.com/kutuphane-programi/indir",
    description: "",
  },
  { text: "Barkod okuma ve yazdırma", href: "", description: "" },
  {
    text: "Veri aktarma sihirbazı",
    href: "",
    description:
      "Excel dosyanız üzerinden topluca kitap, üye ve diğer verileri topluca aktarın",
  },
  { text: "Detaylı raporlama", href: "", description: "" },
  {
    text: "Çoklu kullanıcı ile kullanım",
    href: "",
    description:
      "Aynı bilgisayardaki farklı kullanıcı hesaplarını aynı veritabanına bağlayın",
  },
  {
    text: "Sunucu Paylaşımı",
    href: "",
    description: "Kütüphanenizdeki birden fazla bilgisayarda kullanın",
  },
  { text: "Ücretsiz barkod okuyucu cihaz", href: "", description: "" },
  {
    text: "Kurumunuzun adı, logosu ve ikonu",
    href: "",
    description:
      "Kodvizit'in belirlemiş olduğu logolar yerine kendinize ait logoları ve ikonları kullanın",
  },
  {
    text: "Kitap listesi paylaşım ekranı",
    href: "",
    description:
      "Kütüphanenize gelen üyeler aradıkları kitapları ve bulundukları konumları kendileri bulsun!",
  },
  { text: "Sanal Destek + Telefon", href: "", description: "" },
]
