import { faBookmark, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import PropTypes from "prop-types"
import React from "react"
import Tooltip from "react-bootstrap/Tooltip"
import { kutuphaneSpecs } from "constant/pricing-specs"
import useTranslations from "components/use-translations"

export const PricingTableContainer = ({ children, className, style }) => {
  return (
    <div
      className={`mt-5 d-flex  flex-wrap justify-content-center ${className}`}
      style={style}
    >
      {children}
    </div>
  )
}
PricingTableContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const PricingTable = ({
  header,
  price,
  amount,
  href,
  colorName,
  featured,
  includingModules,
  excludingModules,
  buttonText,
}) => {
  let includedLiList = includingModules.map(module => {
    var spec = kutuphaneSpecs.find(x => x.text === module)
    const href = spec && spec.href,
      description = spec && spec.description

    return (
      <li key={module}>
        <FontAwesomeIcon
          className={`text-${colorName} mr-3`}
          icon={faCheckCircle}
        />
        {href ? (
          <a className="text-dark" href={href}>
            {module}
          </a>
        ) : description ? (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{description}</Tooltip>}
          >
            <abbr>{module}</abbr>
          </OverlayTrigger>
        ) : (
          module
        )}
      </li>
    )
  })
  let excludedLiList = excludingModules.map(module => {
    return (
      <li key={module} style={{ paddingLeft: 35, color: "#ddd" }}>
        {module}
      </li>
    )
  })
  const {
    genel: { sureyleKullanilabilir, satinAl },
  } = useTranslations()
  return (
    <div
      className={`text-${colorName}  mr-lg-4 mb-3 mb-lg-0 ${
        featured && "shadow-sm"
      }  border`}
      style={{ width: 350, paddingTop: !featured && 80 }}
    >
      {featured && (
        <FontAwesomeIcon
          style={{ marginBottom: 31 }}
          size="3x"
          icon={faBookmark}
        ></FontAwesomeIcon>
      )}
      <div className={`text-${colorName}`}>
        <h4 className="font-weight-bold  ">{header}</h4>
        <h5 className="mb-4" style={{ fontSize: 20 }}>
          {price}
        </h5>
      </div>
      <a
        href={href}
        role="button"
        className={`btn btn-${colorName} w-75 font-weight-bold mb-4`}
      >
        {buttonText || <span>{satinAl}</span>}
      </a>
      <h5
        className=" text-secondary font-weight-bold m-0 mb-1"
        style={{ fontSize: 20 }}
      >
        {amount}
      </h5>
      <h5 className=" text-secondary mb-4" style={{ fontSize: 16 }}>
        {sureyleKullanilabilir}
      </h5>
      <ul className="list-unstyled text-left text-dark">
        {includedLiList}
        {excludedLiList}
      </ul>
    </div>
  )
}
PricingTable.propTypes = {
  header: PropTypes.string,
  price: PropTypes.string,
  amount: PropTypes.string,
  featured: PropTypes.bool,
  href: PropTypes.string,
  colorName: PropTypes.string,
  includingModules: PropTypes.array,
  excludingModules: PropTypes.array,
  buttonText: PropTypes.string,
}
